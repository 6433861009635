import * as React from 'react'
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import CodeSnippet from "../components/CodeSnippet"

import '../templates/blog.css'
import '../styles/prism-theme.css'

const components = {
    pre: (props) => <CodeSnippet {...props} />,
};

const MdxBlock = (props) => {
  return <MDXProvider components={components}>
      <MDXRenderer>{props.mdx.body}</MDXRenderer>
    </MDXProvider>
}
export default MdxBlock;