import React from "react";
import Highlight, { defaultProps } from "prism-react-renderer";
import theme from 'prism-react-renderer/themes/nightOwlLight'

import '../styles/prism-theme.css'
import Prism from "prism-react-renderer/prism"
(typeof global !== "undefined" ? global : window).Prism = Prism;
require("prismjs/components/prism-swift");

const getParams = (className = ``) => {
    const [lang = ``, params = ``] = className.split(`:`);
    return [lang.split(`language-`).pop().split(`{`).shift()].concat(
        params.split(`&`).reduce((merged, param) => {
        const [key, value] = param.split(`=`);
        merged[key] = value;
        return merged;
        }, {})
    );
};

const SyntaxHiglight = (props) => {

    const className = props.children.props.className || "";
    const [language, { title = `` }] = getParams(className);

    return (
        <Highlight
        {...defaultProps}
        theme={theme}
        code={props.children.props.children.trim()}
        language={language}
        >
        {({ className, style, tokens, getLineProps, getTokenProps }) => (
            <div className="code-block">
                <pre className={className} style={style}>
                {tokens.map((line, i) => (
                    <div {...getLineProps({ line, key: i })}>
                    {line.map((token, key) => (
                        <span {...getTokenProps({ token, key })} />
                    ))}
                    </div>
                ))}
                </pre>
            </div>
        )}
        </Highlight>
    );
};

const CodeSnippet = (props) => <SyntaxHiglight {...props} />;
export default CodeSnippet;